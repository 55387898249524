import Veggies from '../Images/metadata/Veggies.png';
import VeggiesVegetable from '../Images/metadata/Veggies-Vegetable.png';
import VeggiesFruit from '../Images/metadata/Veggies-Fruit.png';
import VeggiesMushroom from '../Images/metadata/Veggies-Mushroom.png';
import VeggiesProcessedVegetables from '../Images/metadata/Veggies-Processed-Vegetables.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const veggies: IMetadataMap = {
  122: {
    en: {
      title: 'Buy fresh vegetables, mushrooms, fruits at Kamereo',
      description:
        'Kamereo provides wholesale and retail fresh vegetables, mushrooms, fruits at Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: Veggies,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Rau củ, nấm, trái cây tươi ngon, đảm bảo nguồn gốc',
      description:
        'Kamereo cung cấp sỉ, lẻ các sản phẩm Rau lá, củ quả, trái cây giá tốt tại thành phố Hồ Chí Minh. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi',
      image: {
        url: Veggies,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  16: {
    en: {
      title: 'Buy fresh vegetables, roots, tubers at Kamereo',
      description:
        'Buy wholesale, retail fresh vegetables, roots, tubers at good prices, guaranteed origin, many attractive promotions, delivery to your place from Kamereo',
      image: {
        url: VeggiesVegetable,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Rau lá, củ quả các loại tươi ngon, giá tốt tại Kamereo',
      description:
        'Mua sỉ, lẻ các sản phẩm Rau lá, củ quả giá tốt, nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi tại Kamereo',
      image: {
        url: VeggiesVegetable,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  69: {
    en: {
      title: 'Buy fresh, safe, good price mushrooms at Kamereo',
      description:
        'Buy fresh, good price mushrooms, guaranteed origin, many attractive promotions, delivery to your place from Kamereo',
      image: {
        url: VeggiesMushroom,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Nấm tươi ngon, an toàn, giá tốt tại Kamereo',
      description:
        'Mua ngay Nấm các loại tươi ngon, giá tốt, có nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi tại Kamereo.',
      image: {
        url: VeggiesMushroom,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  17: {
    en: {
      title: 'Buy Lemons, oranges, fruits at Kamereo',
      description:
        'Providing fresh, diverse fruits, goods daily. Order now, delivery to your place, guaranteed quality',
      image: {
        url: VeggiesFruit,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Chanh, cam, trái cây các loại tại Kamereo',
      description:
        'Cung cấp Trái cây tươi ngon, đa dạng, hàng có mỗi ngày. Đặt ngay, giao hàng tận nhà, cam kết chất lượng',
      image: {
        url: VeggiesFruit,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  71: {
    en: {
      title: 'Buy processed vegetables at Kamereo',
      description:
        'Tofu, pickles,... processed vegetables are delicious, diverse, convenient and time-saving. Order online with good prices, support delivery to your place',
      image: {
        url: VeggiesProcessedVegetables,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Rau củ chế biến sẵn tiện lợi, giá tốt tại Kamereo',
      description:
        'Đậu hũ, dưa chua,... rau củ chế biến sẵn thơm ngon, chủng loại đa dạng, tiện lợi và tiết kiệm thời gian chế biến. Đặt mua online với giá tốt, có hỗ trợ giao hàng tận nơi.',
      image: {
        url: VeggiesProcessedVegetables,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default veggies;
