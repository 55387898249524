import Dairy from '../Images/metadata/Dairy.png';
import DairyYogurt from '../Images/metadata/Dairy-Yogurt.png';
import DairyMilk from '../Images/metadata/Dairy-Milk.png';
import DairyCream from '../Images/metadata/Dairy-Cream.png';
import DairyCheese from '../Images/metadata/Dairy-Cheese.png';
import DairyButter from '../Images/metadata/Dairy-Butter.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const dairy: IMetadataMap = {
  123: {
    en: {
      title: 'Supply Milk, butter, cheese, fresh cream,... at stable prices',
      description:
        'Kamereo provides wholesale and retail Milk, butter, cheese, fresh cream,... at good prices in Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: Dairy,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Sữa, bơ, phô mai, kem tươi,... giá ổn định',
      description:
        'Kamereo cung cấp sỉ, lẻ các sản phẩm Sữa, bơ, phô mai, kem tươi,... giá tốt tại thành phố Hồ Chí Minh. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi',
      image: {
        url: Dairy,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  19: {
    en: {
      title: 'Fresh milk, condensed milk, soy milk at good prices at Kamereo',
      description:
        'Wholesale and retail Fresh milk, condensed milk, soy milk Vinamilk, TH true Milk, Dalat Milk,... genuine. Support invoice, delivery to your place.',
      image: {
        url: DairyMilk,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Sữa tươi, sữa đặc, sữa đậu nành giá tốt tại Kamereo',
      description:
        'Sỉ lẻ Sữa tươi, sữa đặc, sữa đậu nành Vinamilk, TH true Milk, Dalat Milk,... chính hãng. Có xuất hóa đơn, giao hàng tận nơi.',
      image: {
        url: DairyMilk,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  72: {
    en: {
      title: 'Fresh milk cream, rich cream, cooked cream at Kamereo',
      description:
        'Providing Fresh milk cream, rich cream,... for coffee, restaurants, hotels.... Buy Online at Kamereo. Support invoice. Delivery on time',
      image: {
        url: DairyCream,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Kem sữa tươi, kem béo, kem nấu tại Kamereo',
      description:
        'Cung cấp Kem sữa tươi, kem béo,... cho cà phê, nhà hàng, khách sạn.... Mua hàng Online tại Kamereo. Có xuất hóa đơn. Giao hàng đúng giờ',
      image: {
        url: DairyCream,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  22: {
    en: {
      title: 'Buy Salted butter, unsalted butter, vegan butter at Kamereo',
      description:
        'Buy delicious Salted butter, unsalted butter, vegan butter, good price, guaranteed origin, attractive promotions, delivery to your place at Kamereo.',
      image: {
        url: DairyButter,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Bơ lạt, bơ mặn, bơ thực vật giá tốt tại Kamereo',
      description:
        'Mua ngay các sản phẩm Bơ lạt, bơ mặn, bơ thực vật thơm ngon, giá tốt, nguồn gốc đảm bảo, khuyến mãi hấp dẫn, giao hàng tận nơi tại Kamereo.',
      image: {
        url: DairyButter,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  20: {
    en: {
      title: 'Supply Cheese, various types for restaurants, hotels',
      description:
        'Buy Mozzarella cheese, Cheddar cheese, sliced cheese, cream cheese... guaranteed origin, expiry date. Buy Online, delivery on time at Kamereo.',
      image: {
        url: DairyCheese,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Phô mai các loại cho nhà hàng, khách sạn',
      description:
        'Mua ngay Phô mai Mozzarella, phô mai Cheddar, phô mai lát, phô mai kem... đảm bảo nguồn gốc, hạn dùng. Mua Online giao hàng đúng giờ tại Kamereo.',
      image: {
        url: DairyCheese,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  73: {
    en: {
      title: 'Supply Yogurt, various types for restaurants, hotels, cafes',
      description:
        'Providing Vinamilk Yogurt, Dalat Milk Yogurt... for coffee, restaurants, hotels. Selling reputable, quality products, fast delivery on time at Kamereo.',
      image: {
        url: DairyYogurt,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Cung cấp Sữa chua các loại cho nhà hàng, khách sạn, cà phê',
      description:
        'Cung cấp Sữa chua Vinamilk, sữa chua Dalat Milk... cho cà phê, nhà hàng, khách sạn. Bán hàng uy tín, chất lượng, giao hàng nhanh đúng giờ tại Kamereo.',
      image: {
        url: DairyYogurt,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default dairy;
