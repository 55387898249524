import Meat from '../Images/metadata/Meat.png';
import MeatPork from '../Images/metadata/Meat-Pork.png';
import MeatEgg from '../Images/metadata/Meat-Egg.png';
import MeatSausageHam from '../Images/metadata/Meat-Sausage-Ham.png';
import MeatChicken from '../Images/metadata/Meat-Chicken.png';
import MeatBeef from '../Images/metadata/Meat-Beef.png';
import MeatPlantbased from '../Images/metadata/Meat-Plant-based.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const meat: IMetadataMap = {
  120: {
    en: {
      title: 'Buy fresh chicken, pork, beef at Kamereo',
      description:
        'Kamereo provides wholesale and retail chicken, pork, beef products at Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: Meat,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua sỉ lẻ Thịt Gà, thịt heo, bò đông lạnh tại Kamereo',
      description:
        'Kamereo cung cấp sỉ, lẻ các sản phẩm Thịt gà, heo, bò giá tốt tại thành phố Hồ Chí Minh. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi',
      image: {
        url: Meat,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  1: {
    en: {
      title: 'Buy fresh chicken drumsticks, chicken wings at Kamereo',
      description:
        'Buy fresh chicken drumsticks, chicken wings, good price, guaranteed origin, many attractive promotions, delivery to your place from Kamereo',
      image: {
        url: MeatChicken,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Đùi gà, má đùi gà tươi ngon, chất lượng và đảm bảo nguồn gốc',
      description:
        'Mua ngay Đùi gà, má đùi gà tươi ngon, chất lượng, được đóng khay sạch sẽ, đảm bảo nguồn gốc tại Kamereo',
      image: {
        url: MeatChicken,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  2: {
    en: {
      title: 'Buy wholesale and retail frozen, safe pork at Kamereo',
      description:
        'Fresh, quality pork, harvested and processed on the same day. Order now at Kamereo for fast delivery and reasonable prices.',
      image: {
        url: MeatPork,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua sỉ lẻ Thịt heo đông lạnh, an toàn tại Kamereo',
      description:
        'Thịt heo tươi ngon, chất lượng, được thu hoạch và sơ chế trong ngày. Đặt mua ngay tại Kamereo để được giao hàng nhanh chóng và giá cả hợp lý.',
      image: {
        url: MeatPork,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  3: {
    en: {
      title: 'Buy fresh beef at Kamereo',
      description:
        'Buy fresh beef, imported beef, guaranteed food safety, many delicious dishes, same-day delivery at Kamereo',
      image: {
        url: MeatBeef,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Thịt bò đông lạnh, đảm bảo nguồn gốc tại Kamereo',
      description:
        'Đặt mua Thịt bò tươi, bò nhập khẩu đảm bảo vệ sinh an toàn thực phẩm, chế biến xào nấu nhiều món ăn ngon, giao hàng trong ngày tại Kamereo.',
      image: {
        url: MeatBeef,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  4: {
    en: {
      title: 'Buy plant-based meat, vegan meat at Kamereo',
      description:
        'Plant-based meat, vegan meat products are safe, genuine, and affordable. Order online for home delivery at Kamereo.',
      image: {
        url: MeatPlantbased,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua thịt chay, thịt thực vật các loại đảm bảo, giá tốt tại Kamereo',
      description:
        'Các sản phẩm thịt chay, thịt thực vật đảm bảo an toàn, hàng chính hãng. Đặt mua online giao hàng tận nơi tại Kamereo.',
      image: {
        url: MeatPlantbased,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  66: {
    en: {
      title: 'Buy safe, good price sausages, cold cuts at Kamereo',
      description:
        'Trusted, cold cuts, sausages are safe, genuine, and affordable. Order online for home delivery at Kamereo.',
      image: {
        url: MeatSausageHam,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Xúc xích, thịt nguội các loại an toàn, giá tốt',
      description:
        'Các sản phẩm Xúc xích tiệt trùng, lạp xưởng đảm bảo an toàn, hàng chính hãng. Đặt mua online giao hàng tận nơi tại Kamereo.',
      image: {
        url: MeatSausageHam,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  5: {
    en: {
      title: 'Buy delicious and safe eggs of all kinds at Kamereo',
      description:
        'Buy fresh chicken, duck, quail eggs, good price, guaranteed origin, many attractive promotions, delivery to your place from Kamereo',
      image: {
        url: MeatEgg,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Trứng các loại ngon, an toàn tại Kamereo',
      description:
        'Mua ngay các sản phẩm Trứng gà, vịt, cút giá tốt, nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi tại Kamereo.',
      image: {
        url: MeatEgg,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default meat;
