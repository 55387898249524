export const getLocalOriginName = (origin?: string) => {
  const storedOrigins = window.localStorage.getItem('origins');
  const lang = window.localStorage.getItem('lang');

  if (!storedOrigins || !lang || !origin) {
    return origin;
  }

  const jsonOrigins = JSON.parse(storedOrigins);

  if (!jsonOrigins || Object?.keys(jsonOrigins)?.length <= 0 || !Object?.keys(jsonOrigins).includes(origin)) {
    return origin;
  }

  return lang === 'vi' ? jsonOrigins[origin]?.nameVn : jsonOrigins[origin]?.name;
};
