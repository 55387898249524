import DryFood from '../Images/metadata/Dry-Food.png';
import DryFoodRice from '../Images/metadata/Dry-Food-Rice.png';
import DryFoodCannedFood from '../Images/metadata/Dry-Food-Canned-Food.png';
import DryFoodFlour from '../Images/metadata/Dry-Food-Flour.png';
import DryFoodOther from '../Images/metadata/Dry-Food-Other.png';
import DryFoodPastaNoodles from '../Images/metadata/Dry-Food-Pasta-Noodles.png';
import { IMetadataMap } from './types';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constant';

const dryfood: IMetadataMap = {
  124: {
    en: {
      title: 'Buy Rice, flour, pasta, canned food, dry food ingredients at Kamereo',
      description:
        'Kamereo provides wholesale and retail Rice, flour, pasta, canned food, dry food ingredients at good prices in Ho Chi Minh City. Guaranteed origin, many attractive promotions, delivery to your place',
      image: {
        url: DryFood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Gạo, bột, mì, thực phẩm, nguyên liệu đóng hộp tại Kamereo',
      description:
        'Kamereo cung cấp sỉ, lẻ các sản phẩm Gạo, bột, mì, thực phẩm, nguyên liệu đóng hộp giá tốt tại thành phố Hồ Chí Minh. Nguồn gốc xuất xứ đảm bảo, nhiều khuyến mãi hấp dẫn, giao hàng tận nơi',
      image: {
        url: DryFood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  74: {
    en: {
      title: 'Buy wholesale and retail pasta, noodles, spaghetti at Kamereo',
      description: 'Pasta, noodles, spaghetti of all kinds. Kamereo delivers on time, to your store.',
      image: {
        url: DryFoodPastaNoodles,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Sỉ lẻ Mì, bún, phở, mì ý spagheti tại Kamereo',
      description:
        'Mì, bún, phở tươi ngon, mì ý spagheti các loại. Kamereo giao hàng đúng giờ, tận nơi tại cửa hàng của bạn.',
      image: {
        url: DryFoodPastaNoodles,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  27: {
    en: {
      title: 'Buy wholesale and retail flour, corn flour at Kamereo',
      description:
        'Buy flour, corn flour at good prices, long expiration date, delivery to your place, support invoice at Kamereo.',
      image: {
        url: DryFoodFlour,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Mua Bột mì, bột bắp các loại giá tốt tại Kamereo',
      description:
        'Mua ngay các loại Bột mì, bột bắp giá tốt, hạn sử dụng xa, giao hàng tận nơi, có hỗ trợ xuất hóa đơn tại Kamereo.',
      image: {
        url: DryFoodFlour,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  26: {
    en: {
      title: 'Buy good quality rice, clean rice at good prices at Kamereo',
      description:
        'Providing good quality rice, clean rice, sticky rice, brown rice,... guaranteed quality, safe for restaurants, hotels, F&B businesses',
      image: {
        url: DryFoodRice,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Gạo ngon, sạch các loại giá tốt, chất lượng tại Kamereo',
      description:
        'Cung cấp Gạo ngon, gạo sạch, gạo nếp, gạo lứt,... đảm bảo chất lượng, an toàn cho nhà hàng, khách sạn, doanh nghiệp F&B',
      image: {
        url: DryFoodRice,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  76: {
    en: {
      title: 'Quality canned food, good price at Kamereo',
      description:
        'Canned food, canned fish, canned meat of all kinds, guaranteed quality, safe for health, diverse types. Order online, easy operation, delivery to your place.',
      image: {
        url: DryFoodCannedFood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Thực phẩm đóng hộp chất lượng, giá tốt tại Kamereo',
      description:
        'Đồ đóng hộp, cá hộp, thịt hộp các loại đảm bảo chất lượng, an toàn cho sức khoẻ, chủng loại đa dạng. Đặt mua online thao tác dễ dàng, giao hàng tận cửa.',
      image: {
        url: DryFoodCannedFood,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
  79: {
    en: {
      title: 'Olive, butter, peanuts, ingredients for restaurants, hotels',
      description:
        'Providing Olive, butter, peanuts, ingredients... guaranteed quality, safe for restaurants, hotels, F&B businesses',
      image: {
        url: DryFoodOther,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
    vi: {
      title: 'Oliu, bơ đậu phộng, nguyên liệu cho nhà hàng, khách sạn',
      description:
        'Cung cấp Oliu, bơ đậu phộng, nguyên liệu... đảm bảo chất lượng, an toàn cho nhà hàng, khách sạn, doanh nghiệp F&B',
      image: {
        url: DryFoodOther,
        height: DEFAULT_HEIGHT,
        width: DEFAULT_WIDTH,
      },
    },
  },
};

export default dryfood;
